import { SVGProps, Ref, forwardRef } from 'react'

const SvgCaretRight = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path fill="currentColor" fillRule="evenodd" d="m10 8.5 4 3.5-4 3.5v-7Z" clipRule="evenodd" />
  </svg>
)

const ForwardRef = forwardRef(SvgCaretRight)
export default ForwardRef
